<template>
    <div class="content">
        <router-link v-if="content.image" :name="content.title" class="card" :to="content.url">
            <picture>
                <template v-for="[format, set] of Object.entries(this.getImageSrcsets(content.image))">
                    <source v-if="format !== originalFormat" :srcset="set" sizes="(max-width: 1100px) 80vw, 30vw"
                        :type="'image/' + format.slice(1)">
                </template>
                <img loading="lazy" decoding="async" :srcset="srcset[originalFormat]"
                    :type="'image/' + originalFormat.slice(1)" sizes="(max-width: 1100px) 80vw, 30vw"
                    :alt="content.image.alternativeText" class="article-image">
            </picture>
            <div class="overlay" :style="{ backgroundColor: `rgba(0, 0, 0, ${overlayBackgroundOpacity})` }">
                <div class="overlay-background" />
                <img class="overlay-graphic" :style="'filter:' + this.overlayFilter" loading="lazy" decoding="async"
                    :src="this.getImageUrl(content.overlayGraphic)" type="svg"
                    alt="Blog article preview overlay graphic">
                <div class="meta">
                    <div class="title">{{ content.title }}</div>
                    <div class="date">{{ content.date }}</div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import { hexToCSSFilter } from 'hex-to-css-filter';

export default {
    name: 'BlogCard',

    props: [
        'content'
    ],

    computed: {
        srcset() {
            return this.getImageSrcsets(this.content.image)
        },

        hasWebp() {
            return srcset['.webp'] ? true : false
        },

        originalFormat() {
            let originalFormat = ""
            for (const [format, _] of Object.entries(this.srcset)) {
                if (format !== '.webp') {
                    originalFormat = format
                }
            }

            return originalFormat
        },

        overlayFilter() {
            const cssFilter = hexToCSSFilter(this.content.color)
            return cssFilter.filter
        },

        overlayBackgroundOpacity() {
            return {
                light: 0.3,
                medium: 0.5,
                heavy: 0.7,
            }[this.content.overlayStrength] ?? 0;
        }
    },
}
</script>

<style lang="scss" scoped>
.content {
    position: relative;

    .card {
        &:hover {
            opacity: 1;
        }

        .article-image {
            display: block;
            width: 100%;
            height: auto;
        }

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                backdrop-filter: blur(2px);
            }

            .overlay-graphic {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                transition: opacity .2s;
                opacity: 0;

                &:hover {
                    opacity: 1;
                }
            }

            .overlay-background {
                // Note: The transition can't be defined directly in the .overlay class because that
                // would also apply it to the text in .meta in WebKit browsers.
                transition: backdrop-filter .2s;
                backdrop-filter: blur(0px);
            }

            .meta {
                color: white;
                text-align: center;
                transition: unset;
                filter: unset;
                pointer-events: none;
                z-index: 999;

                .title {
                    display: relative;
                    font-size: getFontSize('eventOverviewTitle', 'desktop');
                    font-family: getFontFamily('eventOverviewTitle');
                    line-height: 1.3em;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .date {
                    display: relative;
                    margin-bottom: 24px;
                    font-weight: bold;
                    font-size: 16px;
                }

            }
        }
    }
}

/*h3.title {
    color: white;
    text-align: start;

    @media only screen and (max-width: 800px) {
        font-size: 32px !important;
        -webkit-line-clamp: unset !important;
        -webkit-box-orient: unset !important;
    }
}*/
</style>
